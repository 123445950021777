import React, { FC, memo } from 'react';
import styles from './Header.module.scss';
import { MdMenu } from 'react-icons/md';

interface Props {

}

const Header: FC<Props> = memo(() => {
  return (
    <div className={styles.header}>
      <div className={styles.logo} />
      <div className={styles.gnb}>
        <MdMenu />
      </div>
    </div>
  );
});

export default Header;
