import React, { FC, memo, useLayoutEffect, useRef, useState } from "react";
import styles from "./ProductItem.module.scss";
import { Product } from "../../testdata/products";
import Ink from "react-ink";
import comma from "../../cores/comma";
import { MdFavorite, MdChatBubble } from "react-icons/md";
import { Link } from "react-router-dom";

interface Props {
  data: Product;
}

const ProductItem: FC<Props> = memo(({ data }) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [itemWidth, setItemWidth] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    setItemWidth(ref.current.offsetWidth);
  }, []);

  return (
    <Link
      to={{ pathname: `/products/${data.id}` }}
      ref={ref}
      className={styles.productItem}
    >
      <div
        className={styles.image}
        style={{ height: itemWidth, backgroundImage: `url(${data.image})` }}
      />
      <div className={styles.information}>
        <h2 className={styles.price}>{comma(data.price)}원</h2>
        <h1 className={styles.name}>{data.name}</h1>
        <div className={styles.footer}>
          <p className={styles.author}>Vitra</p>
          <p className={styles.additional}>
            <span>
              <MdChatBubble />
              12
            </span>
            <span>
              <MdFavorite />
              12
            </span>
          </p>
        </div>
      </div>
      <Ink />
    </Link>
  );
});

export default ProductItem;
