import React, { FC, memo } from "react";
import styles from "./App.module.scss";
import Header from "../Header/Header";
import { Switch, Route } from "react-router-dom";
import ProductList from "../ProductList/ProductList";
import ProductDetail from "../ProductDetail/ProductDetail";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";
import Embed from '../Embed/Embed';

interface Props {}

const routes = [
  {
    path: "/",
    Component: ProductList,
  },
  {
    path: "/products/:id",
    Component: ProductDetail,
  },
];

const App: FC<Props> = memo(() => {
  const location = useLocation();
  if (location.pathname === '/embed') {
    return <Embed />
  }

  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.body}>
          {routes.map(({ path, Component }) => {
            return (
              <Route key={path} exact path={path}>
                {({ match }) => {
                  return (
                    <CSSTransition
                      in={match !== null}
                      unmountOnExit
                      classNames=""
                      timeout={300}
                    >
                      <div className={styles.page}>
                        <Component />
                      </div>
                    </CSSTransition>
                  );
                }}
              </Route>
            );
          })}
      </div>
    </div>
  );
});

export default App;
