export interface Product {
  id: number;
  image: string;
  name: string;
  price: number;
}

export const products: Array<Product> = [
  {
    id: 1,
    image: require("../images/vitra_chair_01.png").default,
    name: "체어클럽 M1 기본형",
    price: 39500,
  },
  {
    id: 2,
    image: require("../images/vitra_chair_02.jpg").default,
    name: "체어클럽 터치백 고급패브릭",
    price: 58000,
  },
  {
    id: 3,
    image: require("../images/vitra_chair_03.jpg").default,
    name: "다니카 메쉬 의자 헤드형",
    price: 47710,
  },
  {
    id: 4,
    image: require("../images/vitra_chair_04.png").default,
    name: "EX퍼니처 카본체어 TYPE-2",
    price: 275000,
  },
  {
    id: 5,
    image: require("../images/vitra_chair_05.png").default,
    name: "체어클럽 M2 헤더형",
    price: 43800,
  },
  {
    id: 6,
    image: require("../images/vitra_chair_06.jpg").default,
    name: "체어포커스 싱크체어 T1 기본형",
    price: 37500,
  },
  {
    id: 7,
    image: require("../images/vitra_chair_07.jpg").default,
    name: "알파카 게이밍의자 일반형",
    price: 81050,
  },
  {
    id: 8,
    image: require("../images/vitra_chair_08.jpg").default,
    name: "체어클럽 에코듀얼S7",
    price: 59900,
  },
  {
    id: 9,
    image: require("../images/vitra_chair_09.jpg").default,
    name: "체어클럽 iChair480 메쉬의자",
    price: 59700,
  },
  {
    id: 10,
    image: require("../images/vitra_chair_10.jpg").default,
    name: "다니카 로엠 헤드형 화이트 체어",
    price: 57900,
  },
  {
    id: 11,
    image: require("../images/vitra_chair_11.jpg").default,
    name:
      "리빙해피 매트",
    price: 17890,
  },
];
