import React, { FC, memo } from 'react';
import comma from '../../cores/comma';
import { useSpring, animated } from 'react-spring';

interface Props {
  value: number;
}

const NumberEasing: FC<Props> = memo(({ value }) => {
  const prop = useSpring({ from: { value: 0 }, to: { value }, config: { delay: 150, friction: 60, tension: 800, } }) as any;
  return (
    <animated.span>{prop.value.interpolate((v: number) => `${comma(v.toFixed(0))}`)}</animated.span>
  );
});

export default NumberEasing;
