import React, { FC, memo } from 'react';
import styles from './Embed.module.scss';
import Viewer from '../Viewer/Viewer';

interface Props {

}

const Embed: FC<Props> = memo(() => {
  return (
    <div className={styles.embed}>
      <iframe src="https://test.viewer.kubee.cc/jYd4aYp" />
    </div>
  );
});

export default Embed;
