import React, { FC, memo, useEffect, useMemo, useState } from "react";
import styles from "./ProductDetail.module.scss";
import Viewer from "../Viewer/Viewer";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { products } from "../../testdata/products";
import classNames from "classnames";
import NumberEasing from "./NumberEasing";
import { Portal } from "react-portal";
import { useSpring, animated } from "react-spring";
import Ink from "react-ink";

interface Props {}

const ProductDetail: FC<Props> = memo(({}) => {
  const params = useParams();
  const id = useMemo(() => Number(get(params, "id")), [params]);
  const product = useMemo(() => products.find((product) => product.id === id), [
    id,
  ]);

  const [isVisible, setVisible] = useState(false);

  const prop = useSpring({
    opacity: isVisible ? 1 : 0,
    y: isVisible ? 0 : 100,
    config: {
      friction: 60,
      tension: 500,
    },
  }) as any;

  useEffect(() => {
    window.scrollTo(0, 0);

    const timeout = setTimeout(() => {
      setVisible(true);
    }, 750);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!product) {
    return null;
  }

  return (
    <div className={styles.productDetail}>
      <div className={styles.header}>
        <p className={styles.navigator}>제품 &gt; 가구 &gt; 의자</p>
      </div>
      <div className={styles.viewer}>
        <Viewer />
      </div>
      <div className={styles.colorPalette}>
        <div className={classNames(styles.color, styles.color1)} />
        <div className={classNames(styles.color, styles.color2)} />
        <div className={classNames(styles.color, styles.color3)} />
        <div className={classNames(styles.color, styles.color4)} />
      </div>
      <div className={styles.information}>
        <p className={styles.author}>Vitra</p>
        <h1 className={styles.name}>{product.name}</h1>
        <h2 className={styles.price}>
          <NumberEasing value={product.price} />원
        </h2>
        <p className={styles.text}>
          식탁의자는 보기도 좋아야 하지만 디저트를 먹고 나서도 한참을 앉아 있고
          싶을 만큼 편안해야 하죠.
          <br />
          Vitra의 식탁의자는 다양한 식탁과 스타일을 보완할 수 있는 디자인으로
          출시되어 있어서 오랫동안 식사마다 함께할 수 있는 완벽한 의자를 찾으실
          수 있어요.
        </p>
      </div>
      <Portal>
        <animated.div
          style={{
            opacity: prop.opacity,
            transform: prop.y.interpolate((y: number) => `translateY(${y}px)`),
          }}
          className={styles.buyButtonWrap}
        >
          <button>
            구매하기
            <Ink />
          </button>
        </animated.div>
      </Portal>
    </div>
  );
});

export default ProductDetail;
