import React, { FC, memo, useState } from "react";
import styles from "./ProductList.module.scss";
import ProductItem from "../ProductItem/ProductItem";
import { map } from "lodash";
import { products } from "../../testdata/products";
import { CSSTransition } from "react-transition-group";
import { RouteComponentProps } from "react-router-dom";
import { useMount } from "react-use";

interface Props {}

const ProductList: FC<Props> = memo(({ }) => {
  return (
    <div className={styles.productList}>
      <div className={styles.items}>
        {map(products, (product, key) => {
          return <ProductItem key={key} data={product} />;
        })}
      </div>
    </div>
  );
});

export default ProductList;
