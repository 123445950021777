import React, { FC, memo, useEffect, useRef, useState } from "react";
import styles from "./Viewer.module.scss";
// import GLView from '../GLView/GLView';

// @ts-ignore

import isMobile from "is-mobile";
import { animated, useSpring } from "react-spring";
import Ink from "react-ink";

require("@google/model-viewer/dist/model-viewer.js");

interface Props {}

const Viewer: FC<Props> = memo(() => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const modelViewer = document.querySelector("#model-viewer") as any;

    modelViewer.addEventListener("load", () => {
      const center = modelViewer.getCameraTarget();
      const size = modelViewer.getDimensions();
      const x2 = size.x / 2;
      const y2 = size.y / 2;
      const z2 = size.z / 2;
      const scale = 50;

      modelViewer.updateHotspot({
        name: "hotspot-dot+X-Y+Z",
        position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
      });

      modelViewer.updateHotspot({
        name: "hotspot-dim+X-Y",
        position: `${center.x + x2} ${center.y - y2} ${center.z}`,
      });
      modelViewer.querySelector(
        'button[slot="hotspot-dim+X-Y"]'
      ).textContent = `${(size.z * scale).toFixed(0)}cm`;

      modelViewer.updateHotspot({
        name: "hotspot-dot+X-Y-Z",
        position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
      });

      modelViewer.updateHotspot({
        name: "hotspot-dim+X-Z",
        position: `${center.x + x2} ${center.y} ${center.z - z2}`,
      });
      modelViewer.querySelector(
        'button[slot="hotspot-dim+X-Z"]'
      ).textContent = `${(size.y * scale).toFixed(0)}cm`;

      modelViewer.updateHotspot({
        name: "hotspot-dot+X+Y-Z",
        position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
      });

      modelViewer.updateHotspot({
        name: "hotspot-dim+Y-Z",
        position: `${center.x} ${center.y + y2} ${center.z - z2}`,
      });
      modelViewer.querySelector(
        'button[slot="hotspot-dim+Y-Z"]'
      ).textContent = `${(size.x * scale).toFixed(0)}cm`;

      modelViewer.updateHotspot({
        name: "hotspot-dot-X+Y-Z",
        position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
      });

      modelViewer.updateHotspot({
        name: "hotspot-dim-X-Z",
        position: `${center.x - x2} ${center.y} ${center.z - z2}`,
      });
      modelViewer.querySelector(
        'button[slot="hotspot-dim-X-Z"]'
      ).textContent = `${(size.y * scale).toFixed(0)}cm`;

      modelViewer.updateHotspot({
        name: "hotspot-dot-X-Y-Z",
        position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
      });

      modelViewer.updateHotspot({
        name: "hotspot-dim-X-Y",
        position: `${center.x - x2} ${center.y - y2} ${center.z}`,
      });
      modelViewer.querySelector(
        'button[slot="hotspot-dim-X-Y"]'
      ).textContent = `${(size.z * scale).toFixed(0)}cm`;

      modelViewer.updateHotspot({
        name: "hotspot-dot-X-Y+Z",
        position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
      });
    });
  }, []);

  const prop = useSpring({ scale: open ? 1 : 0, opacity: open ? 1 : 0 }) as any;

  useEffect(() => {

  }, []);

  return (
    <div className={styles.viewer}>
      <div
        className={styles.viewer}
        dangerouslySetInnerHTML={{
          __html: `
        <model-viewer
          id="model-viewer"
          className="${styles.inner}"
          src="/static/models/glass11/glass_test_11.gltf"
          camera-controls
          ar
          skybox-image="/static/cathedral02.hdr"
          ar-scale="auto"
          shadow-intensity="0"
          ios-src="/static/models/glass11/glass.usdz#custom=https://ar-viewer-example.we-ar.kr/static/popup.html?allowsContentScaling=0"
          background-color="#455A64"
        >
          <button slot="hotspot-dot+X-Y+Z" class="dot" data-position="1 -1 1" data-normal="1 0 0"></button>
          <button slot="hotspot-dim+X-Y" class="dim" data-position="1 -1 0" data-normal="1 0 0"></button>
          <button slot="hotspot-dot+X-Y-Z" class="dot" data-position="1 -1 -1" data-normal="1 0 0"></button>
          <button slot="hotspot-dim+X-Z" class="dim" data-position="1 0 -1" data-normal="1 0 0"></button>
          <button slot="hotspot-dot+X+Y-Z" class="dot show" data-position="1 1 -1" data-normal="0 1 0"></button>
          <button slot="hotspot-dim+Y-Z" class="dim show" data-position="0 -1 -1" data-normal="0 1 0"></button>
          <button slot="hotspot-dot-X+Y-Z" class="dot show" data-position="-1 1 -1" data-normal="0 1 0"></button>
          <button slot="hotspot-dim-X-Z" class="dim" data-position="-1 0 -1" data-normal="-1 0 0"></button>
          <button slot="hotspot-dot-X-Y-Z" class="dot" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
          <button slot="hotspot-dim-X-Y" class="dim" data-position="-1 -1 0" data-normal="-1 0 0"></button>
          <button slot="hotspot-dot-X-Y+Z" class="dot" data-position="-1 -1 1" data-normal="-1 0 0"></button>
        </model-viewer>
    `,
        }}
      ></div>
      <animated.div
        className={styles.qrCode}
        style={{
          transform: prop.scale.interpolate((s: number) => `scale(${s})`),
          opacity: prop.opacity,
        }}
      ></animated.div>
      {!isMobile() && (
        <button
          className={styles.arButton}
          onClick={() => {
            setOpen((prevState) => !prevState);
          }}
        >
          <Ink />
        </button>
      )}
    </div>
  );
});

export default Viewer;
